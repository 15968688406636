// tabsMapping
export const tabsMapping = {
  data: 'data',
  tag: 'tag',
  trends: 'trends'
}
export const tabsList = {
  [tabsMapping.data]: {
    title: '客户资料',
    secondTitle: '了解基本信息',
    type: tabsMapping.data,
    emptyText: '暂无客户资料'
  }, // 客户资料
  [tabsMapping.tag]: {
    title: '客户标签',
    secondTitle: '了解客户特性',
    type: tabsMapping.tag,
    path: 'customer-tag',
    emptyText: '暂无标签，快给客户添加标签吧~',
    emmptyImg: require('@/assets/img/customer-portrait/no-data.png')
  }, //客户标签
  [tabsMapping.trends]: {
    title: '客户动态',
    secondTitle: '实时掌控动态',
    type: tabsMapping.trends,
    path: 'customer-follow',
    emptyText: '暂无暂无客户资料'
  } // 客户动态;
}

export const portraitImgs = {
  select: require('../../../assets/img/customer-portrait/selected.png'), // 选中时样式,
  unselect: require('../../../assets/img/customer-portrait/no-select.png'), // 未选中图片;
  add: require('../../../assets/img/customer-portrait/add.png'), // 未选中图片;
  addIcon: require('../../../assets/img/group-portrait/writing-fluently-background.svg') // 工作台-群动态点击跳转图标;
}
// <!-- sex=0未知，1男，2女 -->
export const sexIcons = {
  1: require('@/assets/img/common/man.png'),
  2: require('@/assets/img/common/women.png')
}
