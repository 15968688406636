<template>
  <div>
    <div class="flex relative">
      <div
        :class="[
          'ml15',
          dateStatus === 'yesterday' ? 'time-select' : 'time-unselect'
        ]"
        @click="handlTimecClick('yesterday')"
      >
        <span
          :class="[
            dateStatus === 'yesterday'
              ? 'time-text-select'
              : 'time-text-unselect'
          ]"
        >
          昨天
        </span>
      </div>
      <div
        :class="[
          'ml8',
          dateStatus === 'today' ? 'time-select' : 'time-unselect'
        ]"
        @click="handlTimecClick('today')"
      >
        <span
          :class="[
            dateStatus === 'today' ? 'time-text-select' : 'time-text-unselect'
          ]"
        >
          今天
        </span>
      </div>
      <div
        class="ml8 custom-unselect flex flex-justify-center flex-align-center pl5 pr5"
        :class="[
          dateStatus === 'custom' ? 'time-select-custom' : 'time-unselect'
        ]"
        @click="handlTimecClick('custom')"
      >
        <div
          :class="[
            dateStatus === 'custom' ? 'time-text-select' : 'time-text-unselect'
          ]"
        >
          <!-- 自定义 -->
          <span class="txt-nowrap" v-if="showCustomDate.startTime">
            {{ showCustomDate.startTime }}-{{ showCustomDate.endTime }}
          </span>
          <span v-else class="txt-nowrap">自定义</span>
        </div>
        <!-- <img
          :src="
            require(`../../../assets/img/group-portrait/${
              dateStatus === 'custom' ? 'down-active' : 'caret-down'
            }.svg`)
          "
          alt=""
          class="img-class"
        /> -->
        <img
          :src="
            require(dateStatus === 'custom'
              ? '@/assets/img/group-portrait/down-active.svg'
              : '@/assets/img/group-portrait/caret-down.svg')
          "
          alt=""
          class="img-class"
        />
      </div>
      <!-- 自定义时间遮盖层 -->
      <div
        v-if="customDateShow"
        @click.stop="switchDateCover(false)"
        class="select-date-list-cover"
      >
        <div class="select-date-list bgf br8 flex pt15 pb15" @click.stop>
          <CustomCalendar
            @selectDate="selectCustomDate"
            :propTime="propTime"
          ></CustomCalendar>
        </div>
      </div>
    </div>

    <div class="flex flex-justify-between mt12">
      <div
        :class="[
          'ml15',
          customerStatus === 'customers'
            ? 'group-customers-select'
            : 'group-customers-unselect'
        ]"
        @click="handlCustomerClick('customers')"
      >
        <div
          :class="[
            customerStatus === 'customers'
              ? 'group-customers-num-select'
              : 'group-customers-num-unselect'
          ]"
        >
          {{ echartData.count }}
        </div>
        <div
          :class="[
            customerStatus === 'customers'
              ? 'group-customers-text-select'
              : 'group-customers-text-unselect'
          ]"
        >
          群成员总数
        </div>
      </div>
      <div
        :class="[
          'mr15',
          customerStatus === 'addCustomer'
            ? 'group-customers-select'
            : 'group-customers-unselect'
        ]"
        @click="handlCustomerClick('addCustomer')"
      >
        <div
          :class="[
            customerStatus === 'addCustomer'
              ? 'group-customers-num-select'
              : 'group-customers-num-unselect'
          ]"
        >
          {{ echartData.create_num }}
        </div>
        <div
          :class="[
            customerStatus === 'addCustomer'
              ? 'group-customers-text-select'
              : 'group-customers-text-unselect'
          ]"
        >
          新增群成员总数
        </div>
      </div>
    </div>
    <div
      style="height: 260px; width: 100%"
      class="group-chart-box"
      id="group-chart-box"
    ></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { onMounted, reactive, toRefs, nextTick } from 'vue'
import { getEchartData } from '@/service/group.service.js'
// import CustomCalendar from '../components/CustomCalendar.vue'
import CustomCalendar from './groupCalendar.vue'
import moment from 'moment'

export default {
  components: {
    CustomCalendar
  },
  props: {
    id: {
      type: String
    }
  },
  setup(props, context) {
    const state = reactive({
      echartData: {}, // 折线图数据
      addCustomerData: {}, // 新增成员总数数据
      customersData: {}, // 成员总数数据
      startTime: '',
      endTime: '',
      // yesterday: true, // 昨天
      // today: false, // 今天
      // custom: false, // 自定义
      dateStatus: 'yesterday',
      customerStatus: 'customers',
      customDateShow: false,
      showCustomDate: {
        startTime: '',
        endTime: ''
      },
      propTime: []
      // addCustomer: false, // 新增成员总数
      // customers: true // 成员总数
    })
    const getData = async () => {
      let { id } = props
      let start_time = state.startTime,
        end_time = state.endTime
      // console.log('id', id, start_time, end_time)
      const data = await getEchartData({
        id,
        start_time,
        end_time
      })
      state.echartData = data
      // console.log('data', data)
    }

    // 选择自定义日期
    const selectCustomDate = async (e) => {
      state.startTime = moment(e[0]).format('yyyy-MM-DD')
      state.endTime = moment(e[1]).format('yyyy-MM-DD')
      state.propTime.push(new Date(e[0]), new Date(e[1]))
      // state.endTime = moment(e[1]).format('yyyy-MM-DD')

      state.showCustomDate.startTime = moment(e[0]).format('MM.DD')
      state.showCustomDate.endTime = moment(e[1]).format('MM.DD')
      state.customDateShow = false
      await getData()
      await handlCustomerClick('customers')
      //   initEcharts('group-chart-box', )
    }
    // 切换遮盖层
    const switchDateCover = (type) => {
      if (type) {
        state.customDateShow = type
        console.log('触发关闭函数', state.customDateShow)
        return
      }
      state.customDateShow = !state.customDateShow
    }

    const handlTimecClick = async (e) => {
      state.dateStatus = e
      // console.log('-------')
      if (e === 'yesterday') {
        // state.yesterday = true
        // state.today = false
        let time = 24 * 60 * 60 * 1000
        let timeRange = new Date() - time
        state.startTime = moment(timeRange).format('yyyy-MM-DD')
        state.endTime = moment(timeRange).format('yyyy-MM-DD')
        await getData()
        await handlCustomerClick('customers')
      }
      if (e === 'today') {
        // state.yesterday = false
        // state.today = true
        let timeRange = new Date()
        state.startTime = moment(timeRange).format('yyyy-MM-DD')
        state.endTime = moment(timeRange).format('yyyy-MM-DD')
        await getData()
        await handlCustomerClick('customers')
      }
      if (e === 'custom') {
        // state.customDateShow = true
        switchDateCover()
      }
    }
    const handlCustomerClick = (e) => {
      //   console.log('变动', e)
      state.customerStatus = e
      if (e === 'customers') {
        // state.customers = true
        // state.addCustomer = false
        let chartBox = echarts.getInstanceByDom(
          document.getElementById('group-chart-box')
        )
        // console.log('获取的echartline 示例1', chartBox)
        if (chartBox) {
          chartBox.dispose()
        }
        state.customersData = state.echartData.group_list
        initEcharts('group-chart-box', state.customersData)
        // console.log('customersData', state.customersData)
      }
      if (e === 'addCustomer') {
        // state.customers = false
        // state.addCustomer = true
        let chartBox = echarts.getInstanceByDom(
          document.getElementById('group-chart-box')
        )
        // console.log('获取的echartline 示例2', chartBox)
        if (chartBox) {
          chartBox.dispose()
        }
        state.addCustomerData = state.echartData.create_list
        initEcharts('group-chart-box', state.addCustomerData)
        // console.log('addCustomerData', state.addCustomerData)
      }
      return Promise.resolve(true)
    }
    const initEcharts = (id, data) => {
      // console.log('data', data)
      const option = {
        xAxis: {
          type: 'category',
          data: data.x
        },
        yAxis: {
          type: 'value',
          minInterval: 1
        },
        grid: {
          top: 46
        },
        series: [
          {
            type: 'line',
            data: data.y,
            color: '#1773FA',
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(23,115,250,0.8)'
                },
                {
                  offset: 1,
                  color: 'rgba(23,115,250,0.3)'
                }
              ])
            }
          }
        ]
      }

      nextTick(() => {
        const myChart = echarts.init(document.getElementById(id))
        myChart.setOption(option)
      })
    }
    onMounted(async () => {
      //   console.log('line')
      await handlTimecClick('yesterday')
      await handlCustomerClick('customers')
    })
    return {
      ...toRefs(state),
      getData,
      handlTimecClick,
      initEcharts,
      handlCustomerClick,
      selectCustomDate,
      switchDateCover
    }
  }
}
</script>

<style lang="less" scoped>
.time-select {
  width: 48px;
  height: 28px;
  background: #eff5ff;
  border-radius: 5px;
  text-align: center;
  border: 1px solid #1773fa;
}
.time-select-custom {
  min-width: 75px;
  height: 28px;
  background: #eff5ff;
  border-radius: 5px;
  text-align: center;
  border: 1px solid #1773fa;
}
.time-unselect {
  width: 48px;
  height: 28px;
  background: #f7f7f7;
  border-radius: 5px;
  text-align: center;
  border: 1px solid #f7f7f7;
}
.time-text-select {
  font-size: 12px;
  font-weight: 400;
  color: #1773fa;
  line-height: 28px;
}
.time-text-unselect {
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  line-height: 28px;
}
.custom-unselect {
  box-sizing: content-box;
  min-width: 78px;
  height: 28px;
  background: #f7f7f7;
  border-radius: 5px;
}
.group-customers-unselect {
  width: 170px;
  height: 76px;
  background: #ffffff;
  border: 1px solid #edeef2;
  border-radius: 5px;
}
.group-customers-select {
  width: 170px;
  height: 76px;
  background: #eff5ff;
  border: 1px solid #1773fa;
  border-radius: 5px;
}
.group-customers-num-unselect {
  font-size: 20px;
  font-weight: 500;
  color: #4a4a4a;
  line-height: 20px;
  padding-top: 14px;
  padding-left: 16px;
}
.group-customers-num-select {
  font-size: 20px;
  font-weight: 500;
  color: #1773fa;
  line-height: 20px;
  padding-top: 14px;
  padding-left: 16px;
}
.group-customers-text-unselect {
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
  padding-top: 8px;
  padding-left: 16px;
}
.group-customers-text-select {
  font-size: 12px;
  font-weight: 400;
  color: #1773fa;
  line-height: 20px;
  padding-top: 8px;
  padding-left: 16px;
}
.img-class {
  width: 14px;
  height: 14px;
}

.select-date-list-cover {
  position: absolute;
  top: 40px;
  left: 0;
  background: rgba(17, 17, 17, 0.6);
  width: 100vw;
  height: calc(100vh - 276px);
  z-index: 100;
  //   .select-date-list {
  //     padding-bottom: 30px;
  //   }
}
</style>
