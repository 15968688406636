<template>
  <div class="user user-portrait-box">
    <div v-if="!updown">
      <van-cell-group :border="false" class="user-portrait">
        <van-cell v-if="isNormal" center class="user-info-cell">
          <template #title>
            <div>
              <div class="user-groupName flex">
                {{ portrait.group_name }}
                <p style="color: #1773fa">({{ portrait.total }})</p>
              </div>
              <div class="user-info">
                <p class="user-text">群主：</p>
                <img
                  class="user-avater"
                  :src="
                    portrait.role_pic
                      ? portrait.role_pic
                      : 'https://rescdn.qqmail.com/node/wwmng/wwmng/style/images/independent/DefaultAvatar$73ba92b5.png'
                  "
                />
                <div style="padding-left: 6px">
                  <p class="user-name">
                    {{ portrait.role_name }}/{{ portrait.role_department }}
                  </p>
                </div>
              </div>
              <div class="user-text flex">
                建群时间：
                <p class="user-time">{{ portrait.create_time }}</p>
              </div>
            </div>
          </template>
        </van-cell>
        <van-cell v-else center class="user-input-cell">
          <template #title>
            <div class="customer-follow">
              <van-field
                v-if="clickFouce"
                v-model="content"
                autosize
                type="textarea"
                maxlength="800"
                show-word-limit
                placeholder="记录用户所说的重点！"
              >
                <template #button>
                  <van-button type="primary" @click="onSubmit" class="save-btn">
                    确认
                  </van-button>
                </template>
              </van-field>
              <div class="blur-once" @click="handleOnce" v-else>
                <div class="blur-text">记录用户所说的重点！</div>
                <img
                  :src="
                    require('../../../assets/img/customer-portrait/writing-fluently.svg')
                  "
                  alt=""
                  class="img-class"
                />
              </div>

              <!-- rows="2" -->
              <!-- <van-cell center
                            <template #title>
                                <div>选择客户跟进动作类型</div>
                            </template>
                            <template #default>
                                <van-dropdown-menu active-color="#1989fa">
                                <van-dropdown-item v-model="checked" :options="types" />
                                </van-dropdown-menu>
                            </template>
                            </van-cell> -->
            </div>
          </template>
        </van-cell>
      </van-cell-group>
      <!--tab页面-->
      <div
        class="cl-portrait-tabs"
        :class="{ 'normal-tabs-content': isNormal }"
      >
        <van-tabs v-model="activeTab" @click="onClickTabItem">
          <van-tab
            title-class="cl-portrait-tab"
            v-for="(item, name, index) in tabsList"
            :key="index"
            :name="item.type"
          >
            <template #title>
              <div
                :class="[
                  item.type == activeTab && 'cl-portrait-tabs-active-title',
                  'cl-portrait-tabs-title'
                ]"
                :style="{
                  'background-image': `url(${
                    item.type == activeTab
                      ? portraitImgs.select
                      : portraitImgs.unselect
                  })`
                }"
              >
                <div class="cl-title1">{{ item?.title }}</div>
                <div class="cl-title2">{{ item?.secondTitle }}</div>
              </div>
            </template>
          </van-tab>
          <div class="cl-tab-content">
            <!-- 客户资料 -->
            <div
              class="cl-data-list cl-data-content"
              v-if="activeTab === tabsMapping.data && external_userid"
            >
              <group-echart-line :id="external_userid" />
            </div>
            <!-- 客户标签 -->
            <div
              class="cl-data-list cl-tag-list"
              v-if="activeTab === tabsMapping.tag && external_userid"
            >
              <group-echart-pie :id="external_userid" :isNormal="isNormal" />
            </div>
            <!-- 客户动态 -->
            <div
              class="cl-data-list customer-dynamic"
              v-if="activeTab === tabsMapping.trends && external_userid"
            >
              <van-cell>
                <template #title>
                  <van-list
                    v-model:loading="loading"
                    v-model:error="error"
                    :finished="finished"
                    :finished-text="
                      list.length == 0 ? '暂无数据' : '没有更多了'
                    "
                    error-text="请求失败，点击重新加载"
                    @load="onNext"
                  >
                    <div
                      v-for="(item, idx) in list"
                      :key="idx"
                      class="logs-item"
                    >
                      <div class="item-date">
                        <div class="item-date-content">
                          {{ item.time }}
                        </div>
                      </div>
                      <template v-for="dynamic in item.list" :key="dynamic.id">
                        <dynamic-list
                          :dynamic-obj="dynamic"
                          dynamic-type="group"
                        />
                      </template>
                      <div class="create-time" v-if="item.createTime">
                        {{ item.createTime }}
                      </div>
                    </div>
                  </van-list>
                </template>
              </van-cell>
            </div>
          </div>
        </van-tabs>
      </div>
      <img
        :src="portraitImgs.addIcon"
        class="add-icon"
        @click="onClickBottomBtn"
        v-if="activeTab == tabsMapping.trends && isNormal"
      />
    </div>
    <div v-else>
      <div class="updown-class">
        <img
          src="../../../assets/img/group-portrait/updown.svg"
          alt=""
          class="updown-img-class"
        />
        <div class="updown-text-class">数据同步中，请稍后查看</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Cell, CellGroup, Tabs, Tab, List, Field, Toast } from 'vant'
import { tabsList, tabsMapping, portraitImgs } from './portrait.js'
import groupEchartLine from './groupEchartLine.vue'
import groupEchartPie from './groupEchartPie.vue'
import {
  getGroupContent,
  getDynamicList,
  addGroupDynamic
} from '@/service/group.service.js'
import { pagination } from '../../../utils/pagination.js'
import DynamicList from '../../community/components/DynamicList.vue'
import { useRoute, useRouter } from 'vue-router'
import { reactive, onMounted, toRefs, computed, ref, watch } from 'vue'
import { getCurExternalChat } from '../../../utils/nwechat.js'
export default {
  components: {
    DynamicList,
    groupEchartLine,
    groupEchartPie,
    [List.name]: List,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Field.name]: Field,
    [Toast.name]: Toast
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const state = reactive({
      updown: false,
      external_userid: '', //群id
      portrait: {}, // 群基本信息
      tabsList,
      tabsMapping,
      portraitImgs,
      activeTab: tabsList.data?.type, // tab切换选择
      isNormal: true, // 是否工作台入口
      clickFouce: false, // 双击出现输入框
      content: '' // 输入跟进内容
    })
    // const {
    //   query: { id }
    // } = useRoute()
    // const id = route.query.id || ''
    const params = ref({
      chat_id: ''
    })

    const {
      list,
      params: paramsObj,
      loading,
      finished,
      refreshing,
      error,
      onRefresh,
      onNext
    } = pagination(getDynamicList, { params, pageMode: true })
    // console.log('list.value', list.value)
    const getNewsdynamic = async () => {
      list.value = []
      loading.value = true
      Toast.loading({ message: '加载中...', forbidClick: true })
      if (await onRefresh()) {
        Toast.clear()
        loading.value = false
      }
    }

    const onLoad = async (e) => {
      state.external_userid = e
      params.value.chat_id = e
      const data = await getGroupContent({
        id: state.external_userid
      })
      //   console.log('data', data, state.updown)
      if (data.code === 40001) {
        state.updown = true
      } else {
        state.updown = false
        state.portrait = data.data
      }
    }
    // tab栏切换;
    const onClickTabItem = async (name) => {
      // console.log('onClickTabItem', name, title)
      state.activeTab = name
      router.replace({
        query: {
          type: name,
          id: route.query.id ? state.external_userid : ''
        }
      })
    }
    // 客户动态底部按钮
    const onClickBottomBtn = () => {
      // router.push({
      //     name: ''
      // })
      let { activeTab, external_userid: id, tabsList } = state
      let path = tabsList[activeTab].path
      let type = 'group'
      if (path) {
        router.push({
          name: path,
          params: { id, type }
        })
      }
      //   console.log('11111', activeTab)
    }
    //判断是否工具栏入口
    const enterType = async () => {
      let external_userid = route.query.id || ''
      if (external_userid) {
        // console.log('external_userid', external_userid)
        // 工作台
        state.isNormal = true
        // console.log('工作台', state.isNormal)
        onLoad(external_userid)
      } else {
        // 工具栏
        state.isNormal = false
        // console.log('工具栏', state.isNormal)
        const external_userid = await getCurExternalChat()
        onLoad(external_userid)
      }
    }
    // 提交跟进记录
    const onSubmit = async () => {
      if (state.content.length === 0) {
        Toast('请记录用户所说的重点！')
        return
      }
      try {
        await addGroupDynamic({
          chat_id: state.external_userid,
          content: state.content
        })
        Toast.success('添加成功！')
        state.content = ''
        state.clickFouce = false
        getNewsdynamic()
      } catch (error) {
        Toast.fail('添加失败！')
      }
    }
    // 点击一次获取焦点更换输入框
    const handleOnce = () => {
      state.clickFouce = true
      // console.log('clickFouce')
    }
    // 在dom生成前判断
    state.activeTab = route.query.type || 'data'
    onMounted(async () => {
      console.log('首页构建')
      await enterType()
    })

    return {
      ...toRefs(state),
      route,
      onLoad,
      onClickTabItem,
      //   hasAddIcon,
      list,
      paramsObj,
      loading,
      finished,
      refreshing,
      error,
      onRefresh,
      onNext,
      onClickBottomBtn,
      enterType,
      onSubmit,
      handleOnce,
      getNewsdynamic
    }
  }
}
</script>

<style lang="less" type="text/less" scoped>
@import './portrait.less';
@import '../../../assets/style/theme.less';
.user-avater {
  width: 32px;
  height: 32px;
  border-radius: 4px;
}
.updown-class {
  position: absolute;
  top: 156px;
  left: 58px;
}
.updown-img-class {
  width: 260px;
  height: 172px;
}
.updown-text-class {
  font-size: 14px;
  font-weight: 400;
  color: #5c5c5c;
  line-height: 22px;
  text-align: center;
  margin-top: 8px;
}
.logs-item {
  color: @gray-6;
  background-color: rgba(252, 252, 252, 1);
  > .label {
    padding-bottom: 4px;
    color: @gray-7;
    > .emphasize {
      font-weight: bold;
    }
  }
  .item-date {
    width: 90px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    background: #cecece;
    border-radius: 4px;
    margin: 0 auto 12px;
    .item-date-content {
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
</style>
