<template>
  <div class="echart-pie">
    <van-tabs
      v-if="echartData.length != 0"
      v-model="groupName"
      class="subject-tabs"
    >
      <van-tab v-for="item in echartData.tags" :key="item.group_id">
        <template #title>
          <div
            class="subject-tab flex flex-align-center"
            @click="handlClick(item.group_name)"
          >
            <div
              :class="[
                'tab-group-name',
                item.type === 'HQ' ? 'hq-group-name' : 'service-group-name'
              ]"
            >
              {{ item.group_name }}
            </div>
            <div v-show="item.group_id === groupId" class="active-border"></div>
          </div>
        </template>
      </van-tab>
      <div class="tab-content">
        <div
          style="height: 320px; background: #fff"
          class="group-chart-pie-box"
          id="group-chart-pie-box"
        ></div>
        <div style="width: 100%; height: 4px"></div>
        <div class="relative">
          <div class="label-title pl15 bgf">
            {{ labelName }} (标签营销: {{ labelData.length }})
          </div>
          <div class="mt14 label-send-box" v-if="labelData.length != 0">
            <sendListItem
              v-for="(item, index) in labelData"
              :key="item"
              :chat_id="id"
              :index="index"
              :limitNum="5"
              :mediaList="item.file_arr"
              :sendItem="item"
              backgroundColor="#fff"
              cutColor="#f7f7f7"
              pageType="label"
              :tag_id_arr="labelId"
              :isNormal="isNormal"
            ></sendListItem>
          </div>
          <div v-else class="no-labelData-class">
            <img
              src="../../../assets/img/group-portrait/no-label.svg"
              alt=""
              class="no-labelData-img"
            />
            <div class="no-labelData-text mt8">暂无标签营销</div>
          </div>
        </div>
      </div>
    </van-tabs>
    <div class="no-data-class" v-else>
      <img
        src="../../../assets/img/group-portrait/no-label.svg"
        alt=""
        class="no-data-img"
      />
      <div class="no-data-text">暂无标签</div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { Tabs, Tab } from 'vant'
import customerTitle from '../../../components/customerTitle/index.vue'
import { onMounted, reactive, toRefs, nextTick } from 'vue'
import { getEchartPieData } from '@/service/group.service.js'
import { getLabelSpeechList } from '../../../service/quickSend.service.js'
import sendListItem from '../../../components/quickSend/sendListItem.vue'

export default {
  components: {
    customerTitle,
    sendListItem,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab
  },
  props: {
    id: {
      type: String
    },
    isNormal: {
      type: Boolean
    }
  },
  setup(props, context) {
    const state = reactive({
      echartData: {}, // 饼图总数据
      clickData: [], // 点击对应标签的饼图数据
      labelId: [], // 标签话术最多的id存储，接口格式为数组
      labelName: '', // 标签话术最多的名字存储
      labelData: [], // 标签话术数据
      groupName: '', // 标签组名
      groupId: '' // 标签组ID
    })
    const getData = async () => {
      let { id } = props
      const data = await getEchartPieData({
        id
      })
      //   if (JSON.parse(JSON.stringify(data)) === '[]') {
      //     state.nodata = true
      //   } else {
      //     state.nodata = false
      //   }
      state.echartData = data
      console.log('data', data)
      if (data.length != 0) {
        state.groupName = state.echartData.tags[0].tag
        state.groupId = state.echartData.tags[0].group_id
        let chartBox = echarts.getInstanceByDom(
          document.getElementById('group-chart-pie-box')
        )
        //   console.log('获取的echartpie 示例1', chartBox)
        if (chartBox) {
          chartBox.dispose()
        }
        initEcharts('group-chart-pie-box', state.echartData.tags[0].tag)
        state.labelId = [state.echartData.tags[0].sale_tag_id]
        getLabelData(state.labelId)
        state.labelName = state.echartData.tags[0].sale_tag_name
      }
      //   console.log('labelName', state.labelName)
    }
    const handlClick = (e) => {
      state.groupName = e
      state.echartData.tags.forEach((element) => {
        if (element.group_name === e) {
          state.clickData = element.tag
          state.groupId = element.group_id
          state.labelId = [element.sale_tag_id]
          state.labelName = element.sale_tag_name
        }
      })
      let chartBox = echarts.getInstanceByDom(
        document.getElementById('group-chart-pie-box')
      )
      //   console.log('获取的echartpie 示例2', chartBox)
      if (chartBox) {
        chartBox.dispose()
      }
      //   console.log('eeee', state.clickData)
      initEcharts('group-chart-pie-box', state.clickData)
      getLabelData(state.labelId)
    }
    const getLabelData = async (e) => {
      let { data } = await getLabelSpeechList({
        tag_id_arr: e
      })
      state.labelData = data
      //   console.log('labelData', data)
    }
    const initEcharts = (id, data) => {
      //   console.log('data', data)
      const option = {
        tooltip: {
          trigger: 'item'
        },
        color: [
          '#1773FA',
          '#5470c6',
          '#91cc75',
          '#fac858',
          '#ee6666',
          '#73c0de',
          '#3ba272',
          '#fc8452',
          '#9a60b4',
          '#ea7ccc'
        ],
        // legend: {
        //   //   orient: 'vertical',
        //   //   type: 'scroll',
        //   icon: 'circle',
        //   top: '65%',
        //   itemWidth: 15,
        //   itemHeight: 10,
        //   textStyle: {
        //     fontSize: 12
        //   }
        // },
        series: [
          {
            type: 'pie',
            radius: '75%',
            center: ['50%', '45%'],
            data: data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }

      nextTick(() => {
        let echarrtBox = document.getElementById(id)
        const myChart = echarts.init(echarrtBox)
        myChart.setOption(option)
      })
    }
    onMounted(() => {
      //   console.log('prop', props)
      getData()
    })
    return {
      ...toRefs(state),
      getData,
      handlClick,
      initEcharts,
      getLabelData
    }
  }
}
</script>

<style lang="less" scoped>
.echart-pie {
  position: relative;
  height: 100vh;
}
.no-data-class {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);
}
.no-data-text {
  margin-top: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #5c5c5c;
  line-height: 22px;
  text-align: center;
}
.no-labelData-class {
  height: 300px;
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
}
.no-labelData-text {
  font-size: 14px;
  font-weight: 400;
  color: #5c5c5c;
  line-height: 22px;
  text-align: center;
}
.label-title {
  height: 48px;
  font-size: 16px;
  font-weight: 600;
  line-height: 48px;
  color: #171717;
}
.label-send-box {
  background: #f7f7f7;
  padding: 25px 15px;
}

.subject-tabs {
  :deep(.van-tabs__wrap) {
    background-color: #f7f7f7;
    padding-bottom: 8px;
  }
  :deep(.van-tabs__nav--line) {
    padding: 0 !important;
    justify-content: flex-start !important;

    .van-tab {
      flex: none;
      padding: 0px 8px !important;
      height: 38px;
      font-weight: 400 !important;
      color: #171717 !important;

      .van-tab__text {
        height: 100%;
      }
    }
  }

  :deep(.van-tabs__content) {
    // height: calc(100vh - 228px) !important;
    overflow: hidden;
  }
  .tab-content {
    height: calc(100vh - 250px);
    overflow: auto;
    box-sizing: border-box;
    background: #f7f7f7;
    padding-bottom: env(safe-area-inset-bottom);
  }

  .tab-group-name {
    padding-left: 4px;
    padding-right: 2px;
    font-size: 16px;
  }
  .hq-group-name {
    color: #1773fa;
  }
  .service-group-name {
    color: #ff6f00;
  }

  .subject-tab {
    height: 100%;

    .active-border {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: linear-gradient(
        90deg,
        rgba(23, 115, 250, 0) 0%,
        #1773fa 48%,
        rgba(63, 181, 255, 0) 100%
      );
    }
  }
}
</style>
